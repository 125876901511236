<template>
  <div>
    <Frame title="Unidades de negocio" :loading="loading">
      <Grid
        :data="data"
        :columns="columns"
        :actions="actions"
        addText="Agregar unidad de negocio"
        :showAdd="$can('AddBusinessUnit', 'STM')"
        @addNew="addNew"
        @reloadData="getBusinessUnits"
      />
    </Frame>
    <Modal :active.sync="openModal">
      <BusinessUnitForm :modelToEdit="modelToEdit" :readonly="readonly" />
    </Modal>
  </div>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';
import BusinessUnitForm from './BusinessUnitForm.vue';

export default {
  name: 'BusinessUnits',
  components: {
    BusinessUnitForm,
  },
  data() {
    return {
      data: [],
      openModal: false,
      loading: false,
      modelToEdit: null,
      actions: [
        {
          text: 'Ver',
          callback: this.onView,
        },
      ],
      columns: [
        {
          title: 'Código',
          field: 'code',
          searchable: true,
        },
        {
          title: 'Descripción',
          field: 'description',
          searchable: true,
        },
        {
          title: 'Código postal',
          field: 'codigoPostalDescription',
        },
      ],
      readonly: false,
    };
  },
  methods: {
    onView(item) {
      this.readonly = true;
      this.modelToEdit = {
        Code: item.code,
        Description: item.description,
        pkc_CodigoPostal: item.fkC_CodigoPostal,
        PKBusinessUnit: item.pkLocation,
      };
      this.openModal = true;
    },
    onEdit(item) {
      this.readonly = false;
      this.modelToEdit = {
        Code: item.code,
        Description: item.description,
        pkc_CodigoPostal: item.fkC_CodigoPostal,
        PKBusinessUnit: item.pkLocation,
      };
      this.openModal = true;
    },
    addNew() {
      this.readonly = false;
      this.modelToEdit = null;
      this.openModal = true;
    },
    getBusinessUnits() {
      this.loading = true;
      const apiClient = new ApiClient('businessUnit', 'stamp');
      apiClient.Get().then((data) => {
        this.data = data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getBusinessUnits();
    if (this.$can('EditBusinessUnit', 'STM'))
      this.actions.push({
        text: 'Editar',
        callback: this.onEdit,
      });
  },
};
</script>
