<template>
  <div>
    <Frame title="Unidades de negocio" :titleAction="titleAction">
      <Title title="Datos generales" />

      <Layout>
        <Column size="6">
          <Field label="Código">
            <Input
              :required="true"
              :readonly="readOnly"
              name="Code"
              textTransform="uppercase"
              v-model="model.Code"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="6">
          <Field label="Descripción">
            <Input
              :required="true"
              :readonly="readOnly"
              name="Description"
              v-model="model.Description"
              rules="required"
            />
          </Field>
        </Column>
        <Column>
          <Field label="Código postal">
            <ComboBox
              :required="true"
              :disabled="readOnly"
              name="ZipCode"
              v-model="model.pkc_CodigoPostal"
              modelEndpoint="businessunit/catalogs"
              api="stamp"
              groupData="codigoPostalCatalog"
              rules="required"
            />
          </Field>
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button
            @click="readonly && !readOnly ? onCancelEdit() : $parent.close()"
            color="terciary-outlined"
          >
            {{ readOnly ? 'Salir' : 'Cancelar' }}
          </Button>
          <Button
            v-if="$can('EditBusinessUnit', 'STM')"
            color="secondary"
            @click="readOnly ? onEdit() : onSave()"
          >
            {{ readOnly ? 'Editar' : modelToEdit ? 'Actualizar' : 'Guardar' }}
          </Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
export default {
  name: 'BusinessUnitForm',
  props: {
    modelToEdit: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleAction: 'Agregar',
      model: {
        Code: null,
        Description: null,
        pkc_CodigoPostal: null,
      },
      readOnly: this.readonly,
    };
  },
  mounted() {
    if (this.readonly) this.$root.$emit('changeModeFrame', 'view');

    if (this.modelToEdit) {
      this.titleAction = this.readonly ? 'Ver' : 'Editar';
      this.model = { ...this.modelToEdit };
    }
  },
  methods: {
    onSave() {
      this.save('businessunit', this.model, 'stamp', 'PKBusinessUnit').then((data) => {
        if (data) {
          this.$parent.close();
        }
      });
    },
    onEdit() {
      this.readOnly = false;
      this.titleAction = 'Editar';

      this.$root.$emit('changeModeFrame', 'edit');
    },
    onCancelEdit() {
      this.readOnly = true;
      this.titleAction = 'Ver';

      this.model = JSON.parse(JSON.stringify(this.modelToEdit));
      this.$root.$emit('changeModeFrame', 'view');
    },
  },
};
</script>
